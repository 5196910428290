import React from 'react';
import { graphql } from 'gatsby';
import Row from 'react-bootstrap/Row';
import '../css/Main.css';
import '../css/Contact.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TitleSection from '../components/home/TitleSection';
import MainLayout from '../components/MainLayout';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import LocationComponent from '../components/contact/LocationComponent';
import EMGAccordion from '../components/common/EMGAccordion';

/**
 * Contact page
 */
class Contact extends React.Component {
	
	/**
	 * Constructor
	 * @param {*} props Component props
	 */
	constructor(props) {
		super(props);

		// Ref to cities accordion
		this.citiesAccordion = React.createRef();

		this.sendMailTo = this.sendMailTo.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.setNewState = this.setNewState.bind(this);

		this.state = {
			// is checkbox checked
			checkbox: false,
			// is small version (small screen)
			smallVersion: false
		};
	}

	/**
   * return true if the string follows an email pattern
   * @param {string} mail String to check
   */
	isEmailValid(mail) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		}
		else {
			return false;
		}
	}

	/**
   * Prepare mailto with form
   * @param {Event} event Form submit event
   */
	sendMailTo(event) {
		event.preventDefault();

		// Check if form valid
		if (
			this.state.name &&
			this.state.name !== '' &&
			(this.state.email && this.state.email !== '' && this.isEmailValid(this.state.email)) &&
			(this.state.phone && this.state.phone !== '') &&
			(this.state.message && this.state.message !== '') &&
			this.state.checkbox
		) {
			// Create email body
			var subject = 'Message from EMG-Website';
			var body = '';
			body += 'Name : ' + this.state.name + '\n';
			body += 'Email : ' + this.state.email + '\n';
			body += 'Phone : ' + this.state.phone + '\n';

			if (this.state.company) {
				body += 'Company : ' + this.state.company + '\n';
			}

			body += '\nMessage : \n';
			body += this.state.message;
			var uri = 'mailto:?subject=';
			uri += encodeURIComponent(subject);
			uri += '&body=';
			uri += encodeURIComponent(body);

			event.target.className = '';

			// mailto
			window.location = uri;
		}
		else {
			event.target.className = 'form-invalid';
		}
	}

	/**
	 * Update state based on input change
	 * @param {Event} event Input change event
	 */
	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.id;

		if (event.target.type === 'checkbox') {
			this.setState({
				[name]: target.checked
			});
		}
		else {
			this.setState({
				[name]: value
			});
		}
	}

	/**
	 * Apply new state and adapt accordions
	 * @param {*} newState State to apply
	 */
	setNewState(newState) {
		if (this.citiesAccordion.current) {
			if (newState.smallVersion && !this.state.smallVersion) {
				this.citiesAccordion.current.expand(-1);
			}
			else if (!newState.smallVersion && this.state.smallVersion) {
				this.citiesAccordion.current.expand(0);
			}

			this.setState({
				smallVersion: newState.smallVersion
			});
		}
	}

	render() {
		let contactSectionData = this.props.data.allKontentItemContactpage.nodes[0].elements;

		// Cities accordion data construction
		let citiesAccordionData = [];

		// Countries loop
		for (let i = 0; i < contactSectionData.offices.value.length; i++) {
			let country = contactSectionData.offices.value[i];

			// office loop
			let body = country.elements.offices.value.map((office, index2) => (
				<LocationComponent
					key={'office-' + i + '-' + index2}
					className="contact-offices-office col-lg-4 col-sm-6 mb-5"
					name={office.elements.name.value}
					location={office.elements.location.value}
					phone={office.elements.phone.value}
					fax={office.elements.fax.value}
					email={office.elements.email.value}
					website={office.elements.website.value}
				/>
			));

			let item = {
				title: country.elements.name.value,
				body: body
			};

			citiesAccordionData.push(item);
		}

		let form = (
			<Form onSubmit={this.sendMailTo}>
				<div className="form-row d-flex justify-content-between">
					<div className="form-item">
						<label htmlFor="name">NAME*</label>
						<input type="text" className="form-control" id="name" onChange={this.handleInputChange} />
					</div>
					<div className="form-item">
						<label htmlFor="email">EMAIL*</label>
						<input type="email" className="form-control" id="email" onChange={this.handleInputChange} />
					</div>
				</div>
				<div className="form-row d-flex justify-content-between">
					<div className="form-item">
						<label htmlFor="phone">PHONE*</label>
						<input type="text" className="form-control" id="phone" onChange={this.handleInputChange} />
					</div>
					<div className="form-item">
						<label htmlFor="company">COMPANY</label>
						<input type="text" className="form-control" id="company" onChange={this.handleInputChange} />
					</div>
				</div>
				<div className="form-row d-flex justify-content-between">
					<label htmlFor="message">YOUR MESSAGE*</label>
					<textarea className="form-control" id="message" onChange={this.handleInputChange} />
				</div>
				<div className="contact-form-validation-error-container form-row flex-column justify-content-center align-items-end contact-form-checkbox-line">
					<div className="d-flex flex-row align-items-center">
						<div className="contact-form-validation-error-cross">
							<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 30 30" preserveAspectRatio="none">
								<line x1="3" y1="3" x2="27" y2="27" className="burger-menu-close-line" />
								<line x1="3" y1="27" x2="27" y2="3" className="burger-menu-close-line" />
							</svg>
						</div>
						<div className="contact-form-validation-error contact-form-checkbox-label">Validations errors ocurred.</div>
					</div>
					<div>
						<div className="contact-form-checkbox-label">Please fill the required field and submit again.</div>
					</div>
				</div>
				<div className="form-row d-flex flex-column justify-content-center align-items-end contact-form-checkbox-line">
					<div className="d-flex flex-row align-items-center">
						<label class="contact-form-checkbox-label">
							By filing this information,
							<input type="checkbox" id="checkbox" checked={this.state.checkbox} onChange={this.handleInputChange} />
							<span class="checkmark" />
						</label>
					</div>
					<div>
						<label className="contact-form-checkbox-label" htmlFor="checkbox">
							I agree to the terms of the Privacy Policy.
						</label>
					</div>
				</div>
				<div className="form-row d-flex justify-content-end">
					<button className="emg-black-background emg-button" type="submit">
						<div className="emg-button-mask" />
						SEND
					</button>
				</div>
			</Form>
		);

		return (
			<MainLayout setNewState={this.setNewState} activeItem={5} scrollSnap={false}>
				{/* Title Section */}
				<Row className="nearly-fullheight fullheight">
					<TitleSection
						background={contactSectionData.background.value[0].url}
						title={contactSectionData.title.value}
						text={contactSectionData.subtitle.value}
						lineColor="emg-yellow"
					/>
				</Row>
				{/* Form Section Big version */}
				<Row className="contact-form-section">
					<div className="hidden-small-version contact-form-darkpolygon">
						<svg style={{ width: '100%', height: '100%', position: 'absolute' }} viewBox="0 0 100 100" preserveAspectRatio="none">
							<polygon points="0,0 100,0 100,100" fill="#ffffff" />
						</svg>
					</div>

					<Col lg={{ span: 5, offset: 1 }} xs={{ span: 10, offset: 1 }} className="d-flex flex-column justify-content-between">
						<LocationComponent
							name={contactSectionData.main_location.value[0].elements.name.value}
							location={contactSectionData.main_location.value[0].elements.location.value}
							phone={contactSectionData.main_location.value[0].elements.phone.value}
							fax={contactSectionData.main_location.value[0].elements.fax.value}
						/>

						<div className="contact-location-access d-flex flex-column">
							<div className="contact-location-access-title">{contactSectionData.access_title.value}</div>
							<div
								className="contact-location-access-text"
								dangerouslySetInnerHTML={{ __html: contactSectionData.access_text.value }}
							/>
						</div>
					</Col>
					<Col lg={{ span: 4, offset: 1 }} xs={{ span: 10, offset: 1 }} className="hidden-small-version contact-form-section-content">
						{form}
					</Col>
				</Row>
				{/* Form Section  Small version*/}
				<Row className="hidden-big-version contact-form-section-content contact-form-section-content-small">
					<Col lg={{ span: 5, offset: 1 }} xs={{ span: 10, offset: 1 }}>
						{form}
					</Col>
				</Row>
				{/* Offices Section */}
				<Row className="contact-offices-section">
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="contact-offices-title">OFFICES</div>
						<EMGAccordion
							className="contact-offices-countries"
							items={citiesAccordionData}
							lineColor={'emg-yellow-background'}
							ref={this.citiesAccordion}
						/>
					</Col>
				</Row>
			</MainLayout>
		);
	}
}
export default Contact;
export const query = graphql`
	query Contact {
		allKontentItemContactpage {
			nodes {
				elements {
					access_text {
						value
					}
					access_title {
						value
					}
					background {
						value {
							url
						}
					}
					contact_email {
						value
					}
					main_location {
						value {
							... on kontent_item_contactlocation {
								elements {
									email {
										value
									}
									fax {
										value
									}
									location {
										value
									}
									name {
										value
									}
									phone {
										value
									}
									website {
										value
									}
								}
							}
						}
					}
					title {
						value
					}
					subtitle {
						value
					}
					offices {
						value {
							... on kontent_item_contactcountry {
								elements {
									name {
										value
									}
									offices {
										value {
											... on kontent_item_contactlocation {
												elements {
													email {
														value
													}
													fax {
														value
													}
													location {
														value
													}
													name {
														value
													}
													phone {
														value
													}
													website {
														value
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

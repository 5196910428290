import React from 'react';
import '../../css/contact/LocationComponent.css';

/**
 * Component to display a location (contact page)
 */
class LocationComponent extends React.Component {
    
	render() {
		let blocEmailWebsite = '';

		let email = '';
		let website = '';
		let phone = '';
		let fax = '';

		if (this.props.email) {
			email = (
				<a href={'mailto:' + this.props.email} className="location-mail">
					Email
				</a>
			);
		}
		if (this.props.website) {
			website = (
				<a href={this.props.website} target="_blank" rel="noreferrer" className="location-web">
					Website
				</a>
			);
		}

		if (this.props.website && this.props.email) {
			blocEmailWebsite = (
				<div className="location-mailweb">
					{email} | {website}
				</div>
			);
		}
		else if (this.props.website || this.props.email) {
			blocEmailWebsite = (
				<div className="location-mailweb">
					{email}
					{website}
				</div>
			);
		}

		let blocPhoneFax = "";

		if (this.props.phone){
			phone = this.props.phone;
		}

		if (this.props.fax){
			fax = this.props.fax;
		}

		if (this.props.phone && this.props.fax) {
			blocPhoneFax = (
				<div className="location-phonefax">
					{phone}
					<br/>
					{fax}
				</div>
			);
		}
		else if (this.props.website || this.props.email) {
			blocPhoneFax = (
				<div className="location-phonefax">
					{phone}
					{fax}
				</div>
			);
		}

		return (
			<div className={this.props.className + ' d-flex flex-column'}>
				<div className="location-title">{this.props.name}</div>
				<div className="location-address" dangerouslySetInnerHTML={{ __html: this.props.location }} />
				{blocPhoneFax}
				{blocEmailWebsite}
			</div>
		);
	}
}

export default LocationComponent;
